.abc {
  width: 450px;

  background: white;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 25px 60px 8px 60px;
  border-radius: 15px;
  transition: all 0.3s;
}

 td,  th {
  border: 1px solid white;
  padding: 8px;
  background-color: #EBEBEB;
  border-radius: 5px;
}

 tr:nth-child(even){background-color: #f2f2f2;}

 tr:hover {background-color: #ddd;}

th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #EBEBEB;
  color: #FF8C00;
}

.btnve{
  width: 300px;
}