* {
  box-sizing: border-box;
}

body {
  background-size: cover;
  background-image: url("../images/backimage.jpg");
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  min-height: 100vh;
  display: flex;
  font-weight: 400;
  font-family: sans-serif;
}

body,
html,
.App,
#root,
.outer {
  width: 100%;
  height: 100%;
}

.outer {
  display: flex;
}

.inner {
  width: 385px;
  display: flex;
  margin: auto;
  background: #f5f5f5;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 25px 60px 8px 60px;
  border-radius: 15px;
  transition: all 0.3s;
}

.img {
  background-image: url("../images/mpayicon2.png");
  background-size: 100% 100%;
  height: 20vh;
  width: 30%;
  margin: auto;
}

.larger {
  font-size: 145%;
  font-weight: 600;
  padding-left: 10px;
}

.medium {
  padding-left: 5px;
  font-size: 120%;
  font-weight: 500;
  color: rgb(73, 72, 72);
}

.spcolor {
  color: rgb(255, 94, 0);
}
.txt {
  color: rgb(74, 134, 136);
}
